import Money from '~/models/cart'

export const useFormatMoney = (price: Money, cents: boolean = true) => {
    const formatter = Intl.NumberFormat(
        'en-US',
        {
            style: 'currency',
            currency: price.currency,
            minimumFractionDigits: 2,
            currencyDisplay: 'narrowSymbol'
        }
    )
    return formatter.format(cents ? price.amount / 100 : price.amount)
}